<template>
  <div class="flex flex-col w-full max-w-lg" v-if="product">
    <h2>{{ product.name }}</h2>
    <div class="flex mt-4 gap-2 max-w-md">
      <div class="w-11/12" id="table-img">
        <img
          class="object-scale-down"
          v-bind:src="url + product.tableimg.url"
          alt="Surgical Table"
        />
      </div>
      <div class="w-1/12" id="remote-img">
        <img
          class="object-scale-down"
          v-bind:src="url + product.remoteimg.url"
          alt="Remote"
        />
      </div>
    </div>
    <div class="md:flex flex-col mt-4">
      <h3>{{ product.description }}</h3>
      <div class="flex mt-8">
        <div class="w-1/2" id="product-data">
          <ul class="list-none">
            <li>
              Height: {{ product.height.minHeight }} –
              {{ product.height.maxHeight }} inch
            </li>
            <li v-for="(feature, index) in product.features" :key="index">
              {{ feature.feature }}
            </li>
          </ul>
        </div>
        <PositionGrid :positions="product.positions" />
      </div>
    </div>
  </div>
  <NotFoundPage v-else />
</template>

<script lang="js">
import Strapi from "@/strapi.js"
import PositionGrid from "@/components/PositionGrid.vue"
import NotFoundPage from "@/views/NotFoundPage.vue";

export default {
  name: "ProductDetailPage",
  data() {
    return {
    url: process.env.VUE_APP_STRAPI_URL,
    product: Object,
    error: null};
  },
  provide: {
    url: process.env.VUE_APP_STRAPI_URL,
  },
  created() {
  Strapi.getProduct(this.$route.params.id)
   .then(product => {
    this.product = product.Product;
   })
   .catch(error=>console.log(error))
},

  components: {
  PositionGrid,
    NotFoundPage,
  },
};
</script>

<template>
  <div class="grid grid-cols-2 md:grid-cols-3 items-baseline">
    <PositionGridItem
      v-for="(position, index) in positions"
      :key="index"
      :position="position"
    />
  </div>
</template>

<script lang="js">
import PositionGridItem from "@/components/PositionGridItem.vue";

export default {
  props: ["positions"],
  components: {
    PositionGridItem,
  },
};
</script>

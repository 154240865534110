<template>
  <div class="flex flex-col items-center w-20 max-h-24 pt-4 pl:2 md:w-28">
    <img
      class="object-scale-down max-h-8"
      v-bind:src="url+position.position_img.url"
      v-bind:alt="position.position_name"
    />
    <p class="text-sm text-center leading-tight p-2" v-if="position.angle > 0">
      {{ position.position_name + " " + position.angle + "°" }}
    </p>
    <p v-else>{{ position.position_name }}</p>
  </div>
</template>

<script lang="js">
export default {
  props: ["position"],
  inject: ["url"]
}
</script>
